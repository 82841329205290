import { Component } from '@angular/core';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-version-display',
  templateUrl: './version-display.component.html',
  styleUrl: './version-display.component.scss'
})
export class VersionDisplayComponent {
  version = environment.version;
  date = environment.pushDate;
}
