import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, distinctUntilChanged, switchMap, debounceTime, filter, tap, take } from 'rxjs/operators';
import { Color, ColorBrand, ColorChart, ColorFamily, Step, LevelItem, StepData, Product, ColorSampleSearchResult, PartStepData } from '../models/';
import { environment } from '../../../environments/environment';
import { NotificationService } from '@progress/kendo-angular-notification';
import { TranslateService } from '@ngx-translate/core';
import { GlobalStateService } from '../../services/global-state.service';


export interface ColorStepState {
  isCustomColor: boolean;
  colorSampleNumber: string;
  searchingColor: boolean;
  refCo: string;
  customColorBrandId: string;
  customColorCode: string;
  customColorName: string;
  colorBrands: ColorBrand[];
  colorCharts: ColorChart[];
  colorFamilies: ColorFamily[];
  colors: Color[];
  selectedColor: Color;
  selectedColorChart: string;
  selectedColorFamily: string;
  sgeParts?: string[];
}

const initialColorStepState: ColorStepState = {
  isCustomColor: false,
  colorSampleNumber:"",
  searchingColor: false,
  refCo: "",
  customColorCode: "",
  customColorName: "",
  customColorBrandId: "",
  colorBrands: [],
  colorCharts: [],
  colorFamilies: [],
  colors: [],
  selectedColor: null,
  selectedColorChart: "",
  selectedColorFamily: "",
  sgeParts: null,
}

export interface SelectedProduct {
  qtyType: string;
  partNum: string;
  partDesc: string;
  isMaibecExpress: boolean;
  sellingUOM: string;
  sellingUOMDesc: string;
  qty: number;
  totalDefaultUOMQty: number,
  totalSellingQty: number;
  scrap: number,
  quantities: Array<{ qty: number, length: number }>,
  comment: string;
}

export interface QuantityStepState {
  parts: Product[];
  selectedProduct: SelectedProduct;
}

const initialQuantityStepState: QuantityStepState = {
  parts: null,
  selectedProduct: {
    qtyType: "",
    partDesc: "",
    partNum: "",
    isMaibecExpress:false,
    sellingUOM: "",
    sellingUOMDesc: "",
    qty: 0,
    totalDefaultUOMQty: 0,
    totalSellingQty: 0,
    scrap: 0,
    quantities: [],
    comment: ""
  }
}

export interface ProductState {
  showProductStepperSummary: boolean;
  currentLangId: string;
  currentStepNumber: number;
  steps: Step[];
  selectedLevels: string[];
  currentStepLevelItems: LevelItem[];
  isColorStep: boolean;
  isQtyStep: boolean;
  isSummaryStep: boolean;
  colorStepState: ColorStepState;
  quantityStepState: QuantityStepState;
};

const initialProductState = {
  showProductStepperSummary: false,
  currentLangId: "",
  selectedLevels: ["", "", "", "", "", "", ""],
  currentStepNumber: 0,
  currentStepLevelItems: [],
  steps: [],

  isColorStep: false,
  isQtyStep: false,
  isSummaryStep: false,
  colorStepState: initialColorStepState,
  quantityStepState: initialQuantityStepState
};

let state: ProductState = initialProductState

@Injectable({
  providedIn: 'root'
})
export class ProductFacade {

  readonly baseLevelImageUrl: string = `${environment.webApiUrl}/Images/Level`

  private store = new BehaviorSubject<ProductState>(state);
  private state$ = this.store.asObservable();

  private searchColorSampleResult = new BehaviorSubject<ColorSampleSearchResult>(null);
  public searchColorSampleResult$ = this.searchColorSampleResult.asObservable();

  private ready = new BehaviorSubject<boolean>(false);
  public ready$ = this.ready.asObservable();

  private custNum: number;
  private shipToNum: string;

  private partNum: string;



  // #region Product Step Stream

  showProductStepperSummary$ = this.state$.pipe(
    map(state => { return state.showProductStepperSummary }),
    distinctUntilChanged()
  );

  productStepperSummary$ = this.state$.pipe(
    map(state => {
      if (state.showProductStepperSummary) {
        const selectedPart: Product = state.quantityStepState.parts.find(p => p.partNum === state.quantityStepState.selectedProduct.partNum);
        const color = state.colorStepState.selectedColor;
        const colorName = state.colorStepState.isCustomColor ? state.colorStepState.customColorName : color.colorName;
        return selectedPart.partNum + " - " + selectedPart.partDescription + " - " + colorName;
      }
      else {
        return "";
      }
    }),
    distinctUntilChanged()
  );

  currentLang$ = this.state$.pipe(
    map(state => { return state.currentLangId }),
    distinctUntilChanged()
  );

  selectedLevels$ = this.state$.pipe(
    map(state => { return state.selectedLevels }),
    distinctUntilChanged()
  );

  currentStepLevelItems$ = this.state$.pipe(
    map(state => { return state.currentStepLevelItems }),
    distinctUntilChanged()
  );

  isLastStep$ = this.state$.pipe(
    map(state => { return state.steps.length > 0 && state.steps.length === state.currentStepNumber }),
    distinctUntilChanged()
  );

  steps$ = this.state$.pipe(
    map(state => state.steps),
    distinctUntilChanged()
  );

  currentStep$ = this.state$.pipe(
    map(state => state.steps[state.currentStepNumber]),
    distinctUntilChanged()
  );

  currentStepNumber$ = this.state$.pipe(
    map(state => state.currentStepNumber),
    distinctUntilChanged()
  );

  isColorStep$ = this.state$.pipe(
    map(state => state.isColorStep),
    distinctUntilChanged()
  );

  isQtyStep$ = this.state$.pipe(
    map(state => state.isQtyStep),
    distinctUntilChanged()
  );

  isSummaryStep$ = this.state$.pipe(
    map(state => state.isSummaryStep),
    distinctUntilChanged()
  );

  // #endregion

  // #region Colors Step Stream

  disableNextStep$ = this.state$.pipe(
    map(state => state.colorStepState),
    map(colorStepState => {

      if (!colorStepState.isCustomColor && colorStepState.selectedColor === null) {
        return true;
      }
      else if (colorStepState.isCustomColor) {
        if (colorStepState.customColorBrandId === "") {
          return true;
        }
        else if (colorStepState.customColorCode === "" && colorStepState.customColorName === "") {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false
      }
    }),
    distinctUntilChanged()
  );

  colorStepState$ = this.state$.pipe(
    map(state => state.colorStepState),
    distinctUntilChanged()
  );

  colorCharts$ = this.state$.pipe(
    map(state => state.colorStepState.colorCharts),
    distinctUntilChanged()
  );

  colorFamilies$ = this.state$.pipe(
    map(state => state.colorStepState.colorFamilies),
    distinctUntilChanged()
  );

  colors$ = this.state$.pipe(
    map(state => state.colorStepState.colors),
    distinctUntilChanged()
  );

  isCustomColor$ = this.state$.pipe(
    map(state => state.colorStepState.isCustomColor),
    distinctUntilChanged()
  );

  colorBrands$ = this.state$.pipe(
    map(state => state.colorStepState.colorBrands),
    distinctUntilChanged()
  );

  selectedColor$ = this.state$.pipe(
    map(state => state.colorStepState.selectedColor),
    distinctUntilChanged()
  );

  selectedColorChart$ = this.state$.pipe(
    map(state => state.colorStepState.selectedColorChart),
    distinctUntilChanged()
  );

  selectedColorFamily$ = this.state$.pipe(
    map(state => state.colorStepState.selectedColorFamily),
    distinctUntilChanged()
  );

  sgeParts$ = this.state$.pipe(
    map(state => state.colorStepState.sgeParts),
    distinctUntilChanged()
  )

  topImageUrl$ = this.state$.pipe(
    map(state => {
      const step = state.steps.find(r => r.isLayer === true)
      if (step) {
        return step.selectedLevelImage;
      }
      else {
        return "";
      }
    }),
    distinctUntilChanged()
  );

  // #endregion

  // #region Quantity Step Stream

  parts$ = this.state$.pipe(
    map(state => state.quantityStepState.parts),
    distinctUntilChanged((prev, curr) => { return prev == curr; })
  );

  selectedProduct$ = this.state$.pipe(
    map(state => state.quantityStepState.selectedProduct),
    distinctUntilChanged()
  )

  // #endregion

  constructor(
    private http: HttpClient,
    private notificationSvc: NotificationService,
    private globalStateSvc: GlobalStateService,
    private translate: TranslateService) {

    this.custNum = this.globalStateSvc.custNum;
    this.shipToNum = this.globalStateSvc.shipToNum;
    this.partNum = this.globalStateSvc.partNum ?? "";

    this.translate.onLangChange.subscribe(r => {
      this.ready.next(false);
      this.updateState({ ...initialProductState, currentLangId: r.lang });

      if (this.partNum !== '') {
        this.setupForPartEdit();
      }

    });
    
    if (this.partNum !== "") {
      this.setupForPartEdit();
    }

    combineLatest([this.selectedLevels$, this.currentLang$])
      .pipe(
        filter(([, currentLang]) => { return currentLang != "" }),
        switchMap(([selectedLevels]) => {
          return this.getCurrentStepData(
            this.custNum,
            this.shipToNum,
            selectedLevels[0],
            selectedLevels[1],
            selectedLevels[2],
            selectedLevels[3],
            selectedLevels[4],
            selectedLevels[5],
            selectedLevels[6]);
        })
      )
      .subscribe((stepData) => {
        const steps = [...state.steps];
        let isColorStep = false;

        //Skip NA Step
        if (stepData.items && stepData.items.length === 1 && stepData.items[0].description === "N/A") {
          const currentStepNumber = state.currentStepNumber;
          const selectedLevels = [...state.selectedLevels];
          selectedLevels[currentStepNumber] = stepData.items[0].levelId;
          steps.push({ stepId: "NA", stepName: "N/A" });
          this.updateState({ ...state, steps, selectedLevels, currentStepNumber: currentStepNumber + 1 });
        }
        else {
          steps.push({ stepId: stepData.stepId, stepName: stepData.stepName });
          if (stepData.stepId === "COLOR") {
            isColorStep = true;

          }
          this.updateState({ ...state, steps, isQtyStep: false, isColorStep, currentStepLevelItems: stepData.items });
          this.ready.next(true);
        }
      });
  }

  public setupForPartEdit() {
    this.getPartStepData(this.partNum, this.custNum, this.shipToNum)
      .pipe(
        take(1)
      )
      .subscribe(partStepData => {
        const selectedLevels = partStepData.levels;
        const steps = partStepData.steps;
        let stepWithLayerImage = steps.find(s => s.isLayer === true);
        if (stepWithLayerImage) {
          stepWithLayerImage.selectedLevelImage = stepWithLayerImage.selectedLevelImage ?
            `${this.baseLevelImageUrl}/${stepWithLayerImage.selectedLevelId}/${stepWithLayerImage.selectedLevelImage}` :
            "";
        }
        this.updateState({ ...initialProductState, selectedLevels, steps, currentLangId: state.currentLangId, currentStepNumber: steps.length })
      });

  }

  public revertStepTo(stepNum: number) {

    const selectedLevels = [...state.selectedLevels];
    const steps = [...state.steps];

    steps.splice(stepNum, steps.length - stepNum);

    for (let i = stepNum; i < selectedLevels.length; i++) {
      selectedLevels[i] = "";
    }

    this.updateState({ ...state, quantityStepState: initialQuantityStepState, colorStepState: initialColorStepState, currentStepNumber: stepNum, selectedLevels, steps, currentStepLevelItems: [] });
  }

  public goToQtyStep() {
    const qtyStepNumber = state.steps.findIndex(r => r.stepId === "QUANTITY")
    this.updateState({ ...state, isQtyStep: true, currentStepNumber: qtyStepNumber, showProductStepperSummary: false });
  }

  public resetState(): void {

    if (state.selectedLevels === initialProductState.selectedLevels)
      return;
    this.ready.next(false);
    this.updateState(initialProductState);
  }

  public selectLevel(levelItem: LevelItem): void {

    this.updateState({ ...state, currentStepLevelItems: [] })
    const currentStepNumber = state.currentStepNumber;
    const selectedLevels = [...state.selectedLevels];
    const currentStep: Step = state.steps[currentStepNumber];

    selectedLevels[currentStepNumber] = levelItem.levelId;

    currentStep.selectedLevelDesc = levelItem.description;
    currentStep.selectedLevelImage = levelItem.imageUrl ? `${this.baseLevelImageUrl}/${levelItem.levelId}/${levelItem.imageUrl}` : "";
    currentStep.isLayer = levelItem.isLayer;
    currentStep.bgHexCode = levelItem.bgHexCode;
    this.updateState({ ...state, selectedLevels, currentStepNumber: currentStepNumber + 1, steps: state.steps, isQtyStep: false })

  }

  public showSummary(): void {
    this.updateState({ ...state, isQtyStep: false, isSummaryStep: true })
  }

  public hideSummary(): void {
    this.updateState({ ...state, isQtyStep: false, isSummaryStep: false })
  }

  public getSnapShot(): ProductState {
    return { ...state };
  }


  /////////////////////////////////////////////////////////////////////////////
  // Colors Step Methods
  /////////////////////////////////////////////////////////////////////////////
  public buildSearchColorControl(): FormControl {
    const searchColor = new FormControl();
    searchColor.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.updateState({ ...state, colorStepState: { ...state.colorStepState, isCustomColor: false, colors: [], selectedColor: null, sgeParts: null } })),
        map(keyword => keyword.trim()),
        filter(keyword => keyword !== ""),
        switchMap((keyword: string) => {
          this.updateState({ ...state, colorStepState: { ...state.colorStepState, searchingColor: true } });
          const levels = { ...state.selectedLevels };
          return this.searchColor(keyword,false, this.custNum, this.shipToNum, levels[0], levels[1], levels[2], levels[3], levels[4], levels[5], levels[6]);
        })
      )
      .subscribe((colors: Color[]) => {
        if (colors && colors.length === 0) {
          this.notificationSvc.show({
            content: "No color found for this code or name.",//TODO TRanslate
            position: { horizontal: 'center', vertical: 'top' },
            animation: { type: 'slide', duration: 800 },
            type: { style: 'warning', icon: true }
          });
        }
        this.updateState({ ...state, colorStepState: { ...state.colorStepState, colors, selectedColor: null, searchingColor: false, sgeParts: null } })


      });

    return searchColor;
  }

  public buildSearchColorSampleControl(): FormControl {
    const searchColorSample = new FormControl();

    searchColorSample.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.updateState({ ...state, colorStepState: { ...state.colorStepState, isCustomColor: false } })),
        filter(keyword => keyword.length === 17),
        switchMap((keyword: string) => {
          this.updateState({ ...state, colorStepState: { ...state.colorStepState, searchingColor: true } });
          const lastSelectedLevel = state.selectedLevels[state.currentStepNumber - 1];
          return this.searchColorSample(keyword, lastSelectedLevel)
        })
      )
      .subscribe((result: ColorSampleSearchResult) => {
        if (!result || (result && result.error)) {
          this.updateState({ ...state, colorStepState: { ...state.colorStepState, isCustomColor: false, searchingColor: false, colorSampleNumber:"", sgeParts: null } })
          this.getSearchResultErrorMessage(result?.error).subscribe(errorMessage => {
            this.notificationSvc.show({
              content: errorMessage,
              position: { horizontal: 'center', vertical: 'top' },
              animation: { type: 'slide', duration: 800 },
              type: { style: 'error', icon: true }
            });
          });
        }
        else {
          const levels = { ...state.selectedLevels };
          this.searchColor(result.colorId, true, this.custNum, this.shipToNum, levels[0], levels[1], levels[2], levels[3], levels[4], levels[5], levels[6])
            .subscribe((colors: Color[]) => {
              if (colors && colors.length > 0) {
                this.updateState({ ...state, colorStepState: { ...state.colorStepState, colors, colorSampleNumber: result.colorSampleNumber, selectedColor: null, customColorBrandId: "", customColorCode: "", isCustomColor: false, customColorName: "", searchingColor: false, sgeParts: result.sgeParts ?? [] } })
              }
              else {
                this.searchColorSampleResult.next(result);
                this.updateState({ ...state, colorStepState: { ...state.colorStepState, isCustomColor: true,  searchingColor: false, colorSampleNumber: result.colorSampleNumber, sgeParts: result.sgeParts ?? [] } })
              }
            });
        }
      });

    return searchColorSample;
  }

  public updateSelectedColorChart(selectedColorChart: string) {

    const colorStepState = { ...state.colorStepState };

    colorStepState.selectedColorChart = selectedColorChart;
    colorStepState.selectedColorFamily = null;
    colorStepState.customColorBrandId = ""
    colorStepState.customColorCode = "";
    colorStepState.customColorName = "";

    if (selectedColorChart === "TEI") {
      colorStepState.isCustomColor = true;
    }
    else {
      colorStepState.isCustomColor = false;
      const chart = colorStepState.colorCharts.filter((c) => c.chartId === selectedColorChart);
      colorStepState.colorFamilies = chart.length > 0 ? chart[0].famillies : [];
    }
    colorStepState.colors = [];

    this.updateState({ ...state, colorStepState });
  }

  public updateSelectedFamily(family: ColorFamily) {
    this.updateState({ ...state, colorStepState: { ...state.colorStepState, selectedColorFamily: family.familyId, colors: family.colors } });
  }

  public updateSelectedColor(color: Color) {
    this.updateState({ ...state, colorStepState: { ...state.colorStepState, selectedColor: color } });
  }

  public updateCustomColorBrandId(customColorBrandId: string) {
    this.updateState({ ...state, colorStepState: { ...state.colorStepState, customColorBrandId } });
  }

  public updateCustomColorCode(customColorCode: string) {
    this.updateState({ ...state, colorStepState: { ...state.colorStepState, customColorCode } });
  }

  public updateCustomColorName(customColorName: string) {
    const colorStepState = { ...state.colorStepState, customColorName };
    this.updateState({ ...state, colorStepState });
  }

  public fetchColorChart() {
    const levels = { ...state.selectedLevels };
    this.getInfoForColorStep(this.custNum, this.shipToNum, levels[0], levels[1], levels[2], levels[3], levels[4], levels[5], levels[6])
      .subscribe(colorCharts => {

        //Unstained or Natural Skip Color Step
        if (colorCharts && colorCharts.length && (colorCharts[0].chartId === '000' || colorCharts[0].chartId === '060')) {
          const color = colorCharts[0].famillies[0].colors[0];
          this.updateSelectedColor(color);
          this.updateColorStep('', '', null, '');
        }
        const colorStepState = { ...state.colorStepState, colorCharts };
        this.updateState({ ...state, colorStepState });
      });
  }

  public fetchColorBrands() {
    this.getColorBrands()
      .subscribe(colorBrands => {
        const colorStepState = { ...state.colorStepState, colorBrands };
        this.updateState({ ...state, colorStepState });
      });
  }

  public updateColorStep(refCo: string, customColorBrand: string, customColorId: number, customColorName: string): void {

    const colorState = { ...state.colorStepState, refCo, customColorId, customColorName, customColorBrand };
    const currentStepNumber = state.currentStepNumber;
    const currentStep: Step = state.steps[currentStepNumber];

    currentStep.selectedLevelDesc = colorState.isCustomColor ? `${customColorBrand} ${customColorId} ${customColorName}` : colorState.selectedColor.colorName;

    const steps = [...state.steps];
    let qtyStepName = "";

    this.translate.stream('StepQty').subscribe((res: string) => {
      qtyStepName = res;
    });

    steps.push({ stepId: "QUANTITY", stepName: qtyStepName });


    this.updateState({ ...state, steps, colorStepState: colorState, currentStepNumber: state.currentStepNumber + 1, isColorStep: false, isQtyStep: true });
  }



  /////////////////////////////////////////////////////////////////////////////
  // Quantity Step Methods
  /////////////////////////////////////////////////////////////////////////////
  public fetchPartsForQty() {
    if (!state.quantityStepState.parts) {
      const levels = { ...state.selectedLevels };
      const color = { ...state.colorStepState.selectedColor };
      this.getProductsForQuantityStep(levels[0], levels[1], levels[2], levels[3], levels[4], levels[5], levels[6], color?.colorId ?? "", state.colorStepState.isCustomColor, state.colorStepState.sgeParts)
        .subscribe(parts => {
          const quantityStepState = { ...state.quantityStepState, parts };
          this.updateState({ ...state, quantityStepState })
        });
    }
  }

  public updateSelectedMainProduct(selectedProduct: SelectedProduct) {
    const quantityStepState = { ...state.quantityStepState, selectedProduct };
    this.updateState({ ...state, quantityStepState, showProductStepperSummary: true, isQtyStep: false, currentStepNumber: state.currentStepNumber + 1 });
  }

  updateColorStepState(partialState: Partial<ColorStepState>): void {
    this.store.next({
      ...this.store.value,
      colorStepState: {
        ...this.store.value.colorStepState,
        ...partialState,
      },
    });
  }
  /////////////////////////////////////////////////////////////////////////////
  // Private Methods
  /////////////////////////////////////////////////////////////////////////////
  private updateState(
    newState: ProductState
  ): void {
    //console.log("State: ", newState)
    this.store.next(state = newState);
  }

  private getPartStepData(
    partNum: string,
    custNum: number,
    shipToNum: string
  ): Observable<PartStepData> {

    let params = new HttpParams();
    params = params.append('partNum', partNum);
    params = params.append('custNum', custNum);
    params = params.append('shipToNum', shipToNum);
    const url = `${environment.webApiUrl}/Product/PartStepData`;

    return this.http.get<PartStepData>(url, { params });
  }

  private getCurrentStepData(
    custNum: number,
    shipToNum: string,
    l1: string,
    l2: string,
    l3: string,
    l4: string,
    l5: string,
    l6: string,
    l7: string): Observable<StepData> {

    let params = new HttpParams();
    params = params.append('custNum', custNum);
    params = params.append('shipToNum', shipToNum);
    params = params.append('level1', l1);
    params = params.append('level2', l2);
    params = params.append('level3', l3);
    params = params.append('level4', l4);
    params = params.append('level5', l5);
    params = params.append('level6', l6);
    params = params.append('level7', l7);

    const url = `${environment.webApiUrl}/Product/StepData`;

    return this.http.get<StepData>(url, { params });
  }

  private getProductsForQuantityStep(
    l1: string,
    l2: string,
    l3: string,
    l4: string,
    l5: string,
    l6: string,
    l7: string,
    color: string,
    isCustomColor: boolean,
    sgeParts: string[] | null
  ): Observable<Product[]> {
    let params = new HttpParams();
    params = params.append('custNum', this.custNum);
    params = params.append('shipToNum', this.shipToNum);
    params = params.append('level1', l1);
    params = params.append('level2', l2);
    params = params.append('level3', l3);
    params = params.append('level4', l4);
    params = params.append('level5', l5);
    params = params.append('level6', l6);
    params = params.append('level7', l7);
    params = params.append('color', color);
    params = params.append('isCustomColor', isCustomColor);
  
    if (sgeParts === null) {
      params = params.append('sgeParts', null);
    } else {
      params = params.append('sgeParts', JSON.stringify(sgeParts));
    }
  
    return this.http.get<Product[]>(`${environment.webApiUrl}/Product/GetProductForQuantityStep/`, { params });
  }

  private getInfoForColorStep(
    custNum: number,
    shipToNum: string,
    l1: string,
    l2: string,
    l3: string,
    l4: string,
    l5: string,
    l6: string,
    l7: string
  ): Observable<ColorChart[]> {

    let params = new HttpParams();
    params = params.append('custNum', custNum);
    params = params.append('shipToNum', shipToNum);
    params = params.append('level1', l1);
    params = params.append('level2', l2);
    params = params.append('level3', l3);
    params = params.append('level4', l4);
    params = params.append('level5', l5);
    params = params.append('level6', l6);
    params = params.append('level7', l7);

    return this.http.get<ColorChart[]>(`${environment.webApiUrl}/Color/ColorCharts`, { params });
  }

  private getColorBrands(): Observable<ColorBrand[]> {

    return this.http.get<ColorBrand[]>(`${environment.webApiUrl}/Color/ColorBrands`);
  }

  private getSearchResultErrorMessage(rawMessage: string): Observable<string> {

    if (rawMessage === "ÉCHANTILLON INCONNU") {
      return this.translate.get("err_echantillonIntrouvable");
    }
    else if (rawMessage === "CATÉGORIE INVALIDE") {
      return this.translate.get('err_echantillonVerifFail');
    }
    else if (rawMessage === 'ÉCHANTILLON EXPIRÉ') {
      return this.translate.get('err_echantillon_passe_date');
    }
    else if (rawMessage === 'ÉCHANTILLON NON-VALIDE POUR COULEUR') {
      return this.translate.get('err_echantillonXcouleur');
    }
    else if (rawMessage === 'ÉCHANTILLON NON-VALIDE POUR CATÉGORIE') {
      return this.translate.get('err_produitIncompatibleNoEchantillon');
    }
    else if (rawMessage === 'ÉCHANTILLON NON TERMINÉ') {
      return this.translate.get('err_echantillonNonTermine');
    }
    else {
      return this.translate.get("err_echantillonIntrouvable");
    }
  }

  private searchColor(keyword: string, fromSampleNumber: boolean, custNum: number, shipToNum: string, l1: string, l2: string, l3: string, l4: string, l5: string, l6: string, l7: string ): Observable<Color[]> {
    let params = new HttpParams();
    params = params.append('keyword', keyword);
    params = params.append('fromSampleNumber', fromSampleNumber);
    params = params.append('custNum', custNum);
    params = params.append('shipToNum', shipToNum);
    params = params.append('level1', l1);
    params = params.append('level2', l2);
    params = params.append('level3', l3);
    params = params.append('level4', l4);
    params = params.append('level5', l5);
    params = params.append('level6', l6);
    params = params.append('level7', l7);
   


    return this.http.get<Color[]>(`${environment.webApiUrl}/Color/Search`, { params });
  }

  private searchColorSample(sampleNumber: string, lastSelectedLevel: string): Observable<ColorSampleSearchResult> {
    return this.http.get<ColorSampleSearchResult>(`${environment.webApiUrl}/Color/SearchSample/${sampleNumber}?lastSelectedLevel=${lastSelectedLevel}`);
  }

}
