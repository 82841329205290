<div *ngIf="(compStepFacade.showCompProduct$ | async)" [formGroup]="form" style="margin:10px 20px;">
  <div style="display:flex;flex-wrap:wrap; margin-bottom:15px; gap:20px;">

    <ng-container *ngFor="let item of currentStepCompProducts; let i = index">

      <label class="cnp-comp-product-card"
             [class.cnp-comp-product-card-active]="selectedValues.includes(item.childPartNum)"
             [class.cnp-comp-product-card-focused]="focusedValue === item.childPartNum">
        <img src="{{getImgSource(item)}}" />
        <div formGroupName="{{item.childPartNum}}">
          <div style="display:flex; align-items:center; flex-flow:row">
            <input kendoCheckBox
                   type="checkbox"
                   [size]="'large'"
                   formControlName="selected"
                   (change)="updateSelectedCompProduct(item.childPartNum,item.mandatory)" />
            <span style="margin-left:8px;"> {{item.childDesc}}</span>
          </div>

          <div style="display:flex; align-items:center; flex-flow:row">
            <kendo-numerictextbox [min]="0"
                                  style="width:100px;"
                                  [spinners]="false"
                                  format="n0"
                                  formControlName="qty">
            </kendo-numerictextbox>
            <span style="margin-left:5px;">{{item.childUOM}}</span>
          </div>
          <kendo-formerror *ngIf="form.controls[item.childPartNum].errors?.['byLength']">{{'errPackaging' | translate }} {{item.childPackaging}}</kendo-formerror>
          <div style="background-color:yellow">{{item.childMessage}}</div>

        </div>

      </label>
    </ng-container>
  </div>

  <div style="display:flex; justify-content:space-between">
    <button kendoButton
            *ngIf="!(compStepFacade.isLastStep$|async)"
            class="cnp-button-primary"
            [disabled]="btnDisabled()"
            (click)="nextStepClick()">
      {{ 'btnNextStep' | translate }}
    </button>
    <button kendoButton         
            *ngIf="(globalStateService.tokenType.toUpperCase() === 'CNP' && (compStepFacade.showGoToSummaryBtn$ | async)) ||
                   (globalStateService.tokenType.toUpperCase() === 'EPICOR' && 
                   (compStepFacade.showGoToSummaryBtn$ | async) && (compStepFacade.isLastStep$ | async))"
            class="cnp-button-primary"
            [disabled]="btnDisabled()"
            (click)="goToSummary()">
      {{ 'btnShowSummary' | translate }}
    </button>
  </div>
</div>
