import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule, ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NavigationModule } from '@progress/kendo-angular-navigation';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { CNPModule } from './CNP/CNP.module';
import { AppRoutingModule } from './app-routing.module';

import { ErrorPageComponent } from './error-page/error-page.component';

import { LoginComponent } from './login/login.component';

import { AddTokenHeaderHttpRequestInterceptor } from './interceptors/add-token-header-http-request.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { IconsModule } from '@progress/kendo-angular-icons';
import { VersionDisplayComponent } from './CNP/classic/version-display/version-display.component';



// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    LoginComponent,
    VersionDisplayComponent
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonsModule,
    DropDownsModule,
    ComboBoxModule,
    NotificationModule,
    IconsModule,
    InputsModule,
    LabelModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
    }),
    NavigationModule,
    DialogsModule], providers: [
      { provide: HTTP_INTERCEPTORS, useClass: AddTokenHeaderHttpRequestInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: Window, useValue: window },
      provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
