<ng-container *ngIf="globalStateSvc.appReady">
    <kendo-appbar [themeColor]="'inherit'" class="cnp-header">
      <kendo-appbar-section>
        <img src="../assets/logoMaibec bleu 1.png" />
      </kendo-appbar-section>
      <h1>{{ 'CNP' | translate}}</h1>
      <kendo-appbar-spacer></kendo-appbar-spacer>
      <kendo-appbar-section>
        <span class="welcome">{{ 'lblWelcome' | translate}} {{globalStateSvc.userName}}</span>
        <kendo-dropdownlist [data]="getLanguages()"                           
                            textField="text"
                            valueField="value"
                            fillMode="outline"
                            size="medium"
                            [svgIcon]="chevronDown"
                            [valuePrimitive]="true"
                            [value]="globalStateSvc.langNameID"
                            (valueChange)="languageChange($event)">
        </kendo-dropdownlist>
      </kendo-appbar-section>
    </kendo-appbar>
    <div class="cnp-sub-header">
      <h1>{{ 'lblYourOrder' | translate}}</h1>
    </div>
    <div class="cnp-body">
      <router-outlet></router-outlet>
      <app-version-display></app-version-display>
    </div>
  </ng-container>

<ng-container *ngIf="!globalStateSvc.appReady">
  <router-outlet></router-outlet>
  <app-version-display></app-version-display>
</ng-container>


